// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-builder-tsx": () => import("./../src/pages/builder.tsx" /* webpackChunkName: "component---src-pages-builder-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-polls-tsx": () => import("./../src/pages/my-polls.tsx" /* webpackChunkName: "component---src-pages-my-polls-tsx" */),
  "component---src-pages-poll-tsx": () => import("./../src/pages/poll.tsx" /* webpackChunkName: "component---src-pages-poll-tsx" */),
  "component---src-pages-results-tsx": () => import("./../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */)
}

