export const config = {
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCsaIhyqu2ELfm8kPxw_MtUmEUm_e_ipaQ",
    authDomain: "thisvsthat-4b928.firebaseapp.com",
    databaseURL: "https://thisvsthat-4b928.firebaseio.com",
    projectId: "thisvsthat-4b928",
    storageBucket: "thisvsthat-4b928.appspot.com",
    messagingSenderId: "702351964573",
    appId: "1:702351964573:web:578f6e07085a1d6c419774",
    measurementId: "G-RCHTGP21D1",
  },
};
