import React, { useEffect, useState, ReactNode } from "react";

import {
  UserQuizData,
  QuizData,
  QuizDataResponse,
  QuizAnswersData,
  QuizWithResultsResponse,
} from "./interfaces";
import { fireBaseApp } from "../base";

const initalAuthState = {
  authStatusReported: false,
  isUserSignedIn: false,
  username: null as string | null,
  avatarUrl: "",
  userEmail: null as string | null,
  uid: "",
};

export const AuthContext = React.createContext({
  auth: initalAuthState,
  logout: () => {},
  saveQuiz: async (quiz: QuizData): Promise<boolean> => {
    return false;
  },
  getUserQuizzes: async (): Promise<Record<string, UserQuizData>> => {
    return {};
  },
  getQuizByUuid: async (quizUuid: string): Promise<QuizDataResponse> => {
    return {} as QuizDataResponse;
  },
  getQuizWithResultsByUuid: async (
    quizUuid: string
  ): Promise<QuizWithResultsResponse> => {
    return {} as QuizWithResultsResponse;
  },
  saveQuizResponse: async (quizResponseData: QuizAnswersData) => false,
  signingIn: false,
});

type AuthContextProvider = {
  children: ReactNode;
};

export const AuthContextProvider = (props: AuthContextProvider) => {
  const { children } = props;
  const [authState, setAuthState] = useState(initalAuthState);
  const [signingIn, setSigningIn] = useState(false);
  const logout = async () => {
    try {
      await fireBaseApp.auth().signOut();
      setAuthState(initalAuthState);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserQuizzes = async () => {
    const user = fireBaseApp.auth().currentUser;
    if (user) {
      const db = fireBaseApp.database().ref(`userQuizzes/${user.uid}`);
      return db.once("value").then((snapshot) => {
        return snapshot.val();
      });
    }
  };

  const getQuizByUuid = async (quizUuid: string) => {
    const db = fireBaseApp.database().ref(`quizData/${quizUuid}`);
    return db.once("value").then((snapshot) => {
      return snapshot.val();
    });
  };

  const getQuizWithResultsByUuid = async (
    quizUuid: string
  ): Promise<QuizWithResultsResponse> => {
    const db = fireBaseApp.database().ref(`quizResults/${quizUuid}`);
    return db.once("value").then(async (snapshot) => {
      const submissions = snapshot.val();
      const quizDb = fireBaseApp.database().ref(`quizData/${quizUuid}`);
      return quizDb.once("value").then((snapshot) => {
        return { quizData: snapshot.val(), submissions };
      });
    });
  };

  const saveQuizResponse = async (quizAnswers: QuizAnswersData) => {
    const user = fireBaseApp.auth().currentUser;

    if (user) {
      const db = fireBaseApp
        .database()
        .ref(`quizResults/${quizAnswers.quizUuid}/${quizAnswers.userUuid}`);
      return db
        .once("value")
        .then((snapshot) => {
          db.set(quizAnswers);
          return true;
        })
        .catch((err) => false);
    }
    return false;
  };

  const saveQuiz = async (quiz: QuizData) => {
    const user = fireBaseApp.auth().currentUser;

    if (user) {
      const db = fireBaseApp
        .database()
        .ref(`userQuizzes/${user.uid}/${quiz.uuid}`);
      return db
        .once("value")
        .then((snapshot) => {
          if (!snapshot.exists()) {
            db.set(
              {
                userUid: user.uid,
                quizUuid: quiz.uuid,
                quizName: quiz.name,
                quizStatus: quiz.status,
                createdAt: quiz.createdAt,
                isPublic: quiz.isPublic,
              },
              async (userErr) => {
                if (!userErr) {
                  const quizdb = fireBaseApp
                    .database()
                    .ref(`quizData/${quiz.uuid}`);
                  return quizdb.once("value").then((snapshot) => {
                    if (!snapshot.exists()) {
                      return quizdb.set(
                        {
                          userUid: user.uid,
                          ...quiz,
                        },
                        (quizErr) => {
                          if (!quizErr) {
                            return true;
                          }
                          return false;
                        }
                      );
                    }
                  });
                } else {
                  return false;
                }
              }
            );
            return true;
          }
        })
        .catch((err) => false);
    }
    return false;
  };

  useEffect(() => {
    const unsubscribe = fireBaseApp.auth().onAuthStateChanged((user) => {
      setSigningIn(true);
      if (user) {
        const username = user.displayName;
        const avatarUrl = user.photoURL || "";
        const userEmail = user.email || user.providerData[0].email;
        const uid = user.uid;

        const db = fireBaseApp.database().ref(`users/${user.uid}`);

        db.once("value").then((snapshot) => {
          if (!snapshot.exists()) {
            db.set({
              username,
              email: userEmail,
              profile_picture: avatarUrl,
              phoneNumber: user.phoneNumber,
              providerId: user.providerId,
              uid,
            });
          }
          setAuthState({
            authStatusReported: true,
            isUserSignedIn: !!user,
            uid,
            username,
            avatarUrl,
            userEmail,
          });
          setSigningIn(false);
        });
      } else {
        setAuthState(initalAuthState);
        setSigningIn(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth: authState,
        logout,
        saveQuiz,
        getUserQuizzes,
        getQuizByUuid,
        saveQuizResponse,
        getQuizWithResultsByUuid,
        signingIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
